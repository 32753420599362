.left_box .el-button[data-v-09f4068e] {
  width: 55px;
}
.bottom_big[data-v-09f4068e] {
  height: 660px;
  overflow: hidden;
  padding-left: 12px;
}
.amod_ad[data-v-09f4068e] {
  animation: ad 0.3s 0s both;
}
.amod_more[data-v-09f4068e] {
  animation: more 0.3s 0s both;
}
.amod[data-v-09f4068e] {
  height: 0;
}
.app-container[data-v-09f4068e] {
  position: relative;
  overflow-y: auto;
}
.title_box[data-v-09f4068e] {
  width: 100%;
  line-height: 36px;
  height: 36px;
  font-size: 15px;
  color: #333;
  border: 1px solid #eee;
  border-bottom: none;
}
.title_box .el-col[data-v-09f4068e] {
  padding-left: 5px;
}
.title_box .el-row[data-v-09f4068e] {
  border-bottom: none;
}
.new_table[data-v-09f4068e] {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  min-height: calc(100vh - 214px);
}
.right_box[data-v-09f4068e] {
  float: left;
  width: calc(100% - 180px);
  padding-left: 22px;
  border-left: 5px solid #e6eef8;
}
.right_box .el-tree[data-v-09f4068e] {
  padding-left: 20px;
  padding-top: 20px;
}
.left_box[data-v-09f4068e] {
  float: left;
  width: 180px;
  min-height: calc(100vh - 146px);
  overflow-y: auto;
}
.left_box .btn[data-v-09f4068e] {
  display: flex;
  justify-content: space-evenly;
}
.borders_6[data-v-09f4068e] {
  border-left: 1px solid #eee;
}
.left_tree[data-v-09f4068e] {
  text-align: left;
}
.left_tree h5[data-v-09f4068e] {
  font-size: 19px;
  font-weight: 600;
  padding-bottom: 15px;
  color: #39b0d2;
  line-height: 30px;
  cursor: pointer;
}
.left_tree h5 i[data-v-09f4068e] {
  color: var(--themeColor, #17a2b8) !important;
  line-height: 30px !important;
  transition: transform 0.3s ease-in-out;
}
.left_tree i[data-v-09f4068e] {
  display: inline-block;
  font-size: 14px;
  color: #c0c4cc;
  line-height: 22px;
  margin-right: 2px;
}
.left_tree p[data-v-09f4068e] {
  display: inline-block;
  cursor: pointer;
  transition: all 0.4s;
  font-size: 14px;
  line-height: 30px;
}
.el-checkbox[data-v-09f4068e]:nth-child(4n-1) {
  margin-right: 0;
}
.el-checkbox + .el-checkbox[data-v-09f4068e] {
  margin-left: 0;
}
.el-col[data-v-09f4068e] {
  padding-left: 5px;
}
.borders[data-v-09f4068e] {
  line-height: 42px;
}
.borders .el-row[data-v-09f4068e] {
  border-right: 1px solid #eee;
}
.borders .el-row[data-v-09f4068e]:last-child {
  border-bottom: none;
}
.borders .el-row[data-v-09f4068e]:nth-child(1) {
  border-top: 0px;
}
.el-row[data-v-09f4068e] {
  border-bottom: 1px solid #eee;
}
.el-col-9 .borders_4[data-v-09f4068e] {
  border-left: 1px solid #eee;
}
.bigborder[data-v-09f4068e] {
  border: 1px solid #ccc;
  min-height: calc(100vh - 214px);
  margin: 15px 0 0 0;
}
.borders_3[data-v-09f4068e] {
  border-left: 1px solid #eee;
}
.curs[data-v-09f4068e] {
  color: var(--themeColor, #17a2b8) !important;
}
.borders_4[data-v-09f4068e] {
  border-left: 1px solid #eee;
}
[data-v-09f4068e] .el-dialog {
  margin: 40px auto;
}
[data-v-09f4068e] .el-check-tag {
  padding: 5px 10px;
  margin-right: 10px;
}
.box .el-col-3[data-v-09f4068e]:first-child {
  border-left: 1px solid #eee;
}
.el-table[data-v-09f4068e] .cell {
  white-space: normal;
}
.span_style[data-v-09f4068e] {
  display: inline-block;
  margin-top: 3px;
}
.el-check-tag.is-checked[data-v-09f4068e] {
  background-color: #f0f9f8;
  color: #17a2b8;
  border: 1px solid #17a2b8;
}
.el-check-tag[data-v-09f4068e] {
  font-weight: 500;
  font-size: 12px;
  padding: 3px 6px;
}